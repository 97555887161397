<template>
	<div class="layout-dashboard">
        <div v-if="current_client == null" class="text-center">
            <br />
            <Dropdown v-model="selectedClient" :options="clientList" optionLabel="name" placeholder="Select a Client" class="w-12 md:w-6 mb-3" @change="selectClient" />
            <br />
            or
            <br />
            <Button label="Create a New Client" icon="pi pi-plus" @click="newClient" class="mt-3" />
            <br />
            <br />
            or
            <br />
            <Button label="Generate Refcode" icon="pi pi-filter" @click="showRefcodeToolModal = true" class="mt-3 p-button-info" />
            <br />
        </div>
        <div v-if="current_client && selectedClient">
            <Generator @chooseClient="resetClient" :client_doc="selectedClient" />
        </div>
    </div>
    <Dialog header="Email Refcode Generator" v-model:visible="showRefcodeToolModal" :modal="true" class="w-12 md:w-6" >
        <RefcodeTool />
    </Dialog>
</template>

<script>
import Generator from "./Generator.vue";
import { fireauth, firedb } from "@/firebase";
import { collection, getDoc, getDocs, doc, setDoc, serverTimestamp } from "firebase/firestore";
import RefcodeTool from "./RefcodeTool.vue";

export default {
	data() {
		return {
            current_client: null,
            clientList: [],
            selectedClient: null,
            showRefcodeToolModal: false,
		}
	},
	methods: {
        resetClient() {
            this.current_client = null;
            this.selectedClient = null;
            this.fetchClients();
        },
        selectClient() {
            this.current_client = this.selectedClient;
        },
        async fetchClients() {
            this.clientList = [];
            const querySnapshot = await getDocs(collection(firedb, "clients"));
            querySnapshot.forEach((doc) => {
            this.clientList.push(doc.data());
            });
        },
        async newClient() {
            const docRef = await doc(collection(firedb, "clients"));
            await setDoc(docRef, {
                name: "New Client",
                id: docRef.id,
                abpage: 'opta_kit_q32022_df',
                crm: 'ngp',
                amount_category: 'fixed',
                bgcolor: '#157ebf',
                border: 'solid 1px #2c2c2c',
                border_bottom: 'solid 3px #2c2c2c',
                border_radius: '3px',
                shadow: '0px 5px 8px 0px #2c2c2c',
                font_size: '23px',
                font_color: '#ffffff',
                font_family: 'proxima-nova-condensed, proxima-nova, AvenirNextCondensed-Bold, Avenir, sans-serif',
                line_height: '26px',
                letter_spacing: '0.04em',
                abx: 'express_lane=true',
                single_button_width: '500px',
                single_button_text: 'CUSTOMIZE TEXT',
                btn_amount_1_fixed: '10',
                btn_amount_2_fixed: '25',
                btn_amount_3_fixed: '50',
                btn_amount_4_fixed: '100',
                btn_amount_5_fixed: '250',
                btn_amounts_fixed: '10,25,50,100,250',
                btn_amount_1_hpchigh: '{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*1.25,1,10,50)}}{{else}}10{{end}}',
                btn_amount_2_hpchigh: '{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*2,1,25,100)}}{{else}}25{{end}}',
                btn_amount_3_hpchigh: '{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*4.25,1,50,250)}}{{else}}50{{end}}',
                btn_amount_4_hpchigh: '{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*7.5,1,100,1000)}}{{else}}100{{end}}',
                btn_amount_5_hpchigh: '{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*10.5,1,250,1500)}}{{else}}250{{end}}',
                btn_amounts_hpchigh: '{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*1.25,1,10,50)}}{{else}}10{{end}},{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*2,1,25,100)}}{{else}}25{{end}},{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*4.25,1,50,250)}}{{else}}50{{end}},{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*7.5,1,100,1000)}}{{else}}100{{end}}',
                btn_amount_1_hpclow: '{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*.8,1,10,50)}}{{else}}10{{end}}',
                btn_amount_2_hpclow: '{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*1.25,1,18,100)}}{{else}}25{{end}}',
                btn_amount_3_hpclow: '{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*2.5,1,35,150)}}{{else}}50{{end}}',
                btn_amount_4_hpclow: '{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*5,1,70,250)}}{{else}}100{{end}}',
                btn_amount_5_hpclow: '{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*9.5,1,133,1500)}}{{else}}250{{end}}',
                btn_amounts_hpclow: '{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*.8,1,10,50)}}{{else}}10{{end}},{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*1.25,1,18,100)}}{{else}}25{{end}},{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*2.5,1,35,150)}}{{else}}50{{end}},{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*5,1,70,250)}}{{else}}100{{end}}',
                btn_amount_1_nondonor: '3',
                btn_amount_2_nondonor: '7',
                btn_amount_3_nondonor: '10',
                btn_amounts_nondonor: '3,7,10',
                created_timestamp: serverTimestamp(),
                created_user: fireauth.currentUser.uid
            });
            let docSnap = await getDoc(doc(firedb, "clients", docRef.id));
            this.current_client = docSnap.data();   
            this.selectedClient = docSnap.data();         
        }
	},
    components: {
        Generator,
        RefcodeTool
    },
    mounted() {
        this.fetchClients()
	},
}
</script>

<style scoped>

</style>
