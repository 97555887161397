<template>
	<div class="grid">
        <div class="col-12">
			<div class="card">
				<div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        Client Abbreviation
                    </span>
                    <InputText type="text" v-model="client_abbreviation" placeholder="Ex: VPP, SDP, MTFC, Etc." />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        Date
                    </span>
                    <InputText type="text" v-model="date" placeholder="YYYYMMDD" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        Email Type
                    </span>
                    <InputText type="text" v-model="email_type" placeholder="DF, PET, POLL, SURV, LTR, SPL, INFO, HOL" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        Email Number
                    </span>
                    <InputText type="text" v-model="email_number" placeholder="1, 2, 3, Etc." />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        Test Segment Number
                    </span>
                    <InputText type="text" v-model="test_segment_number" placeholder="1, 2, 3, Etc." />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        Audience
                    </span>
                    <InputText type="text" v-model="audience" placeholder="AC, LMB, CL, OP, NO, IN-X, Etc." />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        Resend
                    </span>
                    <InputSwitch v-model="resend" class="m-2" />
                </div>
                <div class="text-center">
                    <h5>Refcode: {{ refcode }}</h5>
                    <!-- <Button label="Set as Client Refcode" @click="setRefcode" class="mr-2" /> -->
                    <Button label="Copy Refcode" @click="copyThis(refcode)" class="mr-2 p-button-info" />
                    <Button label="Reset" class="p-button-secondary" @click="resetValues" />
                </div>
			</div>
        </div>
	</div>
    <ConfirmDialog></ConfirmDialog>
</template>

<script>
import { copyText } from 'vue3-clipboard';

export default {
    data() {
			return {
                client_abbreviation: "",
                date: "",
                email_type: "",
                email_number: "",
                test_segment_number: "0",
                audience: "",
                resend: false,
			}
		},
    computed: {
        refcode() {
            return this.client_abbreviation + "_" + this.date + "_EM" + this.email_type + "_E" + this.email_number + "_T" + this.test_segment_number + "_" + this.audience + (this.resend ? "_RS" : "");
        }
    },
    emits: ["setRefcode"],
    methods: {
        setRefcode() {
            this.$emit("setRefcode", this.refcode);
            this.resetValues();
        },
        resetValues() {
            this.client_abbreviation = "";
            this.date = "";
            this.email_type = "";
            this.email_number = "";
            this.test_segment_number = "0";
            this.audience = "";
            this.resend = false;
        },
        copyThis(text) {
            copyText(text, undefined, (error) => {
                if (error) {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Could not copy', life: 1000 });
                    console.log(error);
                } else {
                    this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Copied!', life: 1500 });
                }
            })
        },
    },
    mounted() {
    }
}
</script>

<style scoped>
</style>