<template>
    <div v-if="loading" class="text-center">
        <ProgressSpinner />
    </div>
    <div v-if="!loading" class="col-12 card">
        <Menubar :model="menuitems">
            <template #start>
                <h3 class="m-0">{{ current_client ? current_client : ""}}</h3>
            </template>
            <template #end>
               <Button label="Save Changes" @click="saveClient" />
            </template>
        </Menubar>
    </div>
	<div v-if="!loading" class="grid col-12">
		<div class="col-12 md:col-4">
            <div class="card">
            <Panel header="General" class="mb-2">
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        ActBlue Page
                    </span>
                    <InputText type="text" v-model="abpage" />
                    <!-- <Button icon="pi pi-sliders-h" class="p-button-secondary" @click="this.showRefcodeToolModal = true;" /> -->
                </div>
                <div class="formgroup-inline">
                    <div class="field-radiobutton">
                        <RadioButton name="crm" value="ngp" v-model="crm" />
                        <label for="crm">NGP</label>
                    </div>
                    <div class="field-radiobutton">
                        <RadioButton name="crm" value="ak" v-model="crm" />
                        <label for="crm">ActionKit</label>
                    </div>
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        Amounts
                    </span>
                    <Dropdown v-model="amount_category" :options="amount_category_options" optionLabel="name" optionValue="value" placeholder="Select..." class="w-full" />
                </div>
            </Panel>
            <Panel header="Display Options" :toggleable="true" :collapsed="false">
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        bgcolor
                    </span>
                    <InputText type="text" v-model="bgcolor" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        border
                    </span>
                    <InputText type="text" v-model="border" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        border_bottom
                    </span>
                    <InputText type="text" v-model="border_bottom" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        border_radius
                    </span>
                    <InputText type="text" v-model="border_radius" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        shadow
                    </span>
                    <InputText type="text" v-model="shadow" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        font_size
                    </span>
                    <InputText type="text" v-model="font_size" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        font_color
                    </span>
                    <InputText type="text" v-model="font_color" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        font_family
                    </span>
                    <InputText type="text" v-model="font_family" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        line_height
                    </span>
                    <InputText type="text" v-model="line_height" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        letter_spacing
                    </span>
                    <InputText type="text" v-model="letter_spacing" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        abx
                    </span>
                    <InputText type="text" v-model="abx" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        single_button_text
                    </span>
                    <InputText type="text" v-model="single_button_text" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        single_button_width
                    </span>
                    <InputText type="text" v-model="single_button_width" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        hovercolor
                    </span>
                    <InputText type="text" v-model="hovercolor" />
                </div>
            </Panel>
            <Panel header="Button Amounts" :toggleable="true" :collapsed="true">
                <div class="text-red-500 text-center mb-2">Beware!<br />The options below can cause problems with the code if edited incorrectly.</div>
                
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        btn_amount_1_fixed
                    </span>
                    <InputText type="text" v-model="btn_amount_1_fixed" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        btn_amount_2_fixed
                    </span>
                    <InputText type="text" v-model="btn_amount_2_fixed" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        btn_amount_3_fixed
                    </span>
                    <InputText type="text" v-model="btn_amount_3_fixed" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        btn_amount_4_fixed
                    </span>
                    <InputText type="text" v-model="btn_amount_4_fixed" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        btn_amount_5_fixed
                    </span>
                    <InputText type="text" v-model="btn_amount_5_fixed" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        btn_amounts_fixed
                    </span>
                    <InputText type="text" v-model="btn_amounts_fixed" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        btn_amount_1_hpchigh
                    </span>
                    <InputText type="text" v-model="btn_amount_1_hpchigh" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        btn_amount_2_hpchigh
                    </span>
                    <InputText type="text" v-model="btn_amount_2_hpchigh" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        btn_amount_3_hpchigh
                    </span>
                    <InputText type="text" v-model="btn_amount_3_hpchigh" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        btn_amount_4_hpchigh
                    </span>
                    <InputText type="text" v-model="btn_amount_4_hpchigh" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        btn_amount_5_hpchigh
                    </span>
                    <InputText type="text" v-model="btn_amount_5_hpchigh" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        btn_amounts_hpchigh
                    </span>
                    <InputText type="text" v-model="btn_amounts_hpchigh" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        btn_amount_1_hpclow
                    </span>
                    <InputText type="text" v-model="btn_amount_1_hpclow" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        btn_amount_2_hpclow
                    </span>
                    <InputText type="text" v-model="btn_amount_2_hpclow" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        btn_amount_3_hpclow
                    </span>
                    <InputText type="text" v-model="btn_amount_3_hpclow" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        btn_amount_4_hpclow
                    </span>
                    <InputText type="text" v-model="btn_amount_4_hpclow" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        btn_amount_5_hpclow
                    </span>
                    <InputText type="text" v-model="btn_amount_5_hpclow" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        btn_amounts_hpclow
                    </span>
                    <InputText type="text" v-model="btn_amounts_hpclow" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        btn_amount_1_nondonor
                    </span>
                    <InputText type="text" v-model="btn_amount_1_nondonor" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        btn_amount_2_nondonor
                    </span>
                    <InputText type="text" v-model="btn_amount_2_nondonor" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        btn_amount_3_nondonor
                    </span>
                    <InputText type="text" v-model="btn_amount_3_nondonor" />
                </div>
                <div class="p-inputgroup mb-2">
                    <span class="p-inputgroup-addon">
                        btn_amounts_nondonor
                    </span>
                    <InputText type="text" v-model="btn_amounts_nondonor" />
                </div>

                
            </Panel>
            </div>
        </div>
        <div class="col-12 md:col-8">
            <div class="card">
			<Accordion>
                <AccordionTab>
                    <template #header>
                        <div class="col-10 p-0">
                            Full Button Set (Non-ABX)
                        </div>
                        <div class="col-2 p-0 pr-2 text-right">
                            <Button icon="pi pi-copy" @click="copyThis(full_button_set_non_abx)" />
                        </div>
                    </template>
                    <div class="overflow-scroll" v-html="full_button_set_non_abx"></div>
                </AccordionTab>
                <AccordionTab>
                    <template #header>
                        <div class="col-10 p-0">
                            Mid Button Set (Non-ABX)
                        </div>
                        <div class="col-2 p-0 pr-2 text-right">
                            <Button icon="pi pi-copy" @click="copyThis(mid_button_set_non_abx)" />
                        </div>
                    </template>
                    <div class="overflow-scroll" v-html="mid_button_set_non_abx"></div>
                </AccordionTab>
                <AccordionTab>
                    <template #header>
                        <div class="col-10 p-0">
                            Full Button Set (ABX)
                        </div>
                        <div class="col-2 p-0 pr-2 text-right">
                            <Button icon="pi pi-copy" @click="copyThis(full_button_set_abx)" />
                        </div>
                    </template>
                    <div class="overflow-scroll" v-html="full_button_set_abx"></div>
                </AccordionTab>
                <AccordionTab>
                    <template #header>
                        <div class="col-10 p-0">
                            Mid Button Set (ABX)
                        </div>
                        <div class="col-2 p-0 pr-2 text-right">
                            <Button icon="pi pi-copy" @click="copyThis(mid_button_set_abx)" />
                        </div>
                    </template>
                    <div class="overflow-scroll" v-html="mid_button_set_abx"></div>
                </AccordionTab>
                <AccordionTab>
                    <template #header>
                        <div class="col-10 p-0">
                            Single Responsive Button
                        </div>
                        <div class="col-2 p-0 pr-2 text-right">
                            <Button icon="pi pi-copy" @click="copyThis(single_button)" />
                        </div>
                    </template>
                    <div class="overflow-scroll" v-html="single_button"></div>
                </AccordionTab>
                <AccordionTab>
                    <template #header>
                        <div class="col-10 p-0">
                            {{ crm == "ak" ? "Dynamic Amount Twig" : "NGP & EA Donor/NonDonor Twig" }}
                        </div>
                        <div class="col-2 p-0 pr-2 text-right">
                            <Button icon="pi pi-copy" @click="copyThis(donor_nondonor_twig)" />
                        </div>
                    </template>
                    <div class="overflow-scroll" v-html="donor_nondonor_twig"></div>
                </AccordionTab>
            </Accordion>
            </div>
        </div>
	</div>
    <ConfirmDialog></ConfirmDialog>
    <Dialog header="Email Refcode Generator" v-model:visible="showRefcodeToolModal" :modal="true" class="w-12 md:w-6" >
        <RefcodeTool @setRefcode="setRefcode" />
    </Dialog>
    <Dialog header="Rename Client" v-model:visible="showRenameModal" :modal="true" class="w-12 md:w-4" :closeOnEscape="false" :closable="false" >
        <InputText type="text" v-model="current_client" class="w-full" />
        <template #footer>
            <Button label="Cancel" @click="current_client = client_doc.name; showRenameModal = false;" />
            <Button label="Submit" @click="changeName" />
        </template>
    </Dialog>
</template>

<script>
import { fireauth, firedb } from "@/firebase";
import { doc, updateDoc, deleteDoc, serverTimestamp } from "firebase/firestore";
import { copyText } from 'vue3-clipboard';
import RefcodeTool from "./RefcodeTool.vue";

export default {
    props: {
        client_doc: {
            type: Object,
            default: null
        }
    },
    data() {
			return {
                loading: true,
                current_client: "Optakit",
                showRefcodeToolModal: false,
                showRenameModal: false,
                menuitems: [
                    {
                    label:'Rename',
                    icon:'pi pi-fw pi-pencil',
                    command: () => {
                            this.showRenameModal = true;
                        }
                    },
                    {
                    label:'Delete Client',
                    icon:'pi pi-fw pi-trash',
                    command: () => {
                        this.$confirm.require({
                            message: 'This action is irreversible. Proceed?',
                            header: 'Confirm',
                            icon: 'pi pi-exclamation-triangle',
                            acceptClass: 'p-button-danger',
                            acceptLabel: 'Delete Client',
                            accept: () => {
                                this.deleteClient();
                                this.$emit("chooseClient");
                            },
                            reject: () => {
                                return false;
                            }
                        });
                    }
                    },
                    {
                    label:'Switch Client',
                    icon:'pi pi-fw pi-arrows-h',
                    command: () => {
                        this.$confirm.require({
                            message: 'Any unsaved changes will be lost. Proceed?',
                            header: 'Confirm',
                            icon: 'pi pi-exclamation-triangle',
                            accept: () => {
                                this.$emit("chooseClient");
                            },
                            reject: () => {
                                return false;
                            }
                        });
                    }
                    },
                    {
                    label:'Refcode Generator',
                    icon:'pi pi-fw pi-filter',
                    command: () => {
                            this.showRefcodeToolModal = true;
                        }
                    },
                ],

                abpage: 'opta_kit_q32022_df',
                crm: 'ngp',
                amount_category: 'fixed',
                amount_category_options: [
                    {name: "HPC High", value: "hpchigh"},
                    {name: "HPC Low", value: "hpclow"},
                    {name: "Fixed", value: "fixed"},
                ],
                // text: '',
                bgcolor: '#157ebf',
                border: 'solid 1px #2c2c2c',
                border_bottom: 'solid 3px #2c2c2c',
                border_radius: '3px',
                shadow: '0px 5px 8px 0px #2c2c2c',
                font_size: '23px',
                font_color: '#ffffff',
                font_family: 'proxima-nova-condensed, proxima-nova, AvenirNextCondensed-Bold, Avenir, sans-serif',
                line_height: '26px',
                letter_spacing: '0.04em',
                abx: 'express_lane=true',
                single_button_width: '500px',
                single_button_text: 'CUSTOMIZE TEXT',
                hovercolor: 'darkblue',
                btn_amount_1_fixed: '10',
                btn_amount_2_fixed: '25',
                btn_amount_3_fixed: '50',
                btn_amount_4_fixed: '100',
                btn_amount_5_fixed: '250',
                btn_amounts_fixed: '10,25,50,100,250',
                btn_amount_1_hpchigh: '{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*1.25,1,10,50)}}{{else}}10{{end}}',
                btn_amount_2_hpchigh: '{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*2,1,25,100)}}{{else}}25{{end}}',
                btn_amount_3_hpchigh: '{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*4.25,1,50,250)}}{{else}}50{{end}}',
                btn_amount_4_hpchigh: '{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*7.5,1,100,1000)}}{{else}}100{{end}}',
                btn_amount_5_hpchigh: '{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*10.5,1,250,1500)}}{{else}}250{{end}}',
                btn_amounts_hpchigh: '{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*1.25,1,10,50)}}{{else}}10{{end}},{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*2,1,25,100)}}{{else}}25{{end}},{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*4.25,1,50,250)}}{{else}}50{{end}},{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*7.5,1,100,1000)}}{{else}}100{{end}}',
                btn_amount_1_hpclow: '{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*.8,1,10,50)}}{{else}}10{{end}}',
                btn_amount_2_hpclow: '{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*1.25,1,18,100)}}{{else}}25{{end}}',
                btn_amount_3_hpclow: '{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*2.5,1,35,150)}}{{else}}50{{end}}',
                btn_amount_4_hpclow: '{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*5,1,70,250)}}{{else}}100{{end}}',
                btn_amount_5_hpclow: '{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*9.5,1,133,1500)}}{{else}}250{{end}}',
                btn_amounts_hpclow: '{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*.8,1,10,50)}}{{else}}10{{end}},{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*1.25,1,18,100)}}{{else}}25{{end}},{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*2.5,1,35,150)}}{{else}}50{{end}},{{if greater_than(HighestPreviousContributionAmount,0)}}{{round_min_max((HighestPreviousContributionAmount)*5,1,70,250)}}{{else}}100{{end}}',
                btn_amount_1_nondonor: '3',
                btn_amount_2_nondonor: '7',
                btn_amount_3_nondonor: '10',
                btn_amounts_nondonor: '3,7,10'
			}
		},
    emits: ["chooseClient"],
    methods: {
        copyThis(text) {
            copyText(text, undefined, (error) => {
                if (error) {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Could not copy', life: 1000 });
                    console.log(error);
                } else {
                    this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Copied!', life: 1500 });
                }
            })
        },
        setRefcode(value) {
            this.abpage = value;
            this.showRefcodeToolModal = false;
            this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Refcode set', life: 1500 });
        },
        async deleteClient() {
            await deleteDoc(doc(firedb, "clients", this.client_doc.id));
            this.$toast.add({severity:'success', summary: 'Success', detail:'Client deleted', life: 3000});
        },
        async changeName() {
            await updateDoc(doc(firedb, "clients", this.client_doc.id), {
                name: this.current_client,
                last_updated_timestamp: serverTimestamp(),
                last_updated_user: fireauth.currentUser.uid,
            });
            this.showRenameModal = false;
            this.$toast.add({severity:'success', summary: 'Success', detail:'Client name changed', life: 3000});
        },
        async saveClient() {
            let updatedFields = {};
            for (let itemKey of Object.keys(this.client_doc)) {
                if (this[itemKey]) {
                    updatedFields[itemKey] = this[itemKey];
                }
            }
            updatedFields.last_updated_timestamp = serverTimestamp();
            updatedFields.last_updated_user = fireauth.currentUser.uid;
            await updateDoc(doc(firedb, "clients", this.client_doc.id), updatedFields);
            this.$toast.add({severity:'success', summary: 'Success', detail:'Client saved', life: 3000});
        }
    },
    computed: {
        full_button_set_non_abx() {
            return `
            ${ this.crm == 'ak' ? '{% if hpc_raw %}' : '{{if HighestPreviousContributionAmount}}' }
                      <center>
                        <table align="center" cellpadding="0" cellspacing="12" height="45" style="max-width: 100%;" width="320">
                          <tbody>
                            <tr>
                              <td align="center" bgcolor="${ this.bgcolor }" class="hvr-btn-${ this.hovercolor }" height="45" style="max-width: 310px; min-width: 310px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="310"><a class="hvr-btn-${ this.hovercolor }" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.amount_category == 'hpchigh' ? this.btn_amount_1_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_1_hpclow : this.btn_amount_1_fixed }&amounts=${ this.amount_category == 'hpchigh' ? this.btn_amounts_hpchigh : this.amount_category == 'hpclow' ? this.btn_amounts_hpclow : this.btn_amounts_fixed }" style="padding: 12px; background-color: ${ this.bgcolor }; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: ${ this.font_color }; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">CONTRIBUTE ${ this.amount_category == 'hpchigh' ? this.btn_amount_1_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_1_hpclow : this.btn_amount_1_fixed }&nbsp;»</a></td>
                            </tr>
                            <tr>
                              <td align="center" bgcolor="${ this.bgcolor }" class="hvr-btn-${ this.hovercolor }" height="45" style="max-width: 310px; min-width: 310px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="310"><a class="hvr-btn-${ this.hovercolor }" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.amount_category == 'hpchigh' ? this.btn_amount_2_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_2_hpclow : this.btn_amount_2_fixed }&amounts=${ this.amount_category == 'hpchigh' ? this.btn_amounts_hpchigh : this.amount_category == 'hpclow' ? this.btn_amounts_hpclow : this.btn_amounts_fixed }" style="padding: 12px; background-color: ${ this.bgcolor }; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: ${ this.font_color }; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">CONTRIBUTE $${ this.amount_category == 'hpchigh' ? this.btn_amount_2_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_2_hpclow : this.btn_amount_2_fixed }»</a></td>
                            </tr>
                            <tr>
                              <td align="center" bgcolor="${ this.bgcolor }" class="hvr-btn-${ this.hovercolor }" height="45" style="max-width: 310px; min-width: 310px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="310"><a class="hvr-btn-${ this.hovercolor }" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.amount_category == 'hpchigh' ? this.btn_amount_3_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_3_hpclow : this.btn_amount_3_fixed }&amounts=${ this.amount_category == 'hpchigh' ? this.btn_amounts_hpchigh : this.amount_category == 'hpclow' ? this.btn_amounts_hpclow : this.btn_amounts_fixed }" style="padding: 12px; background-color: ${ this.bgcolor }; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: ${ this.font_color }; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">CONTRIBUTE $${ this.amount_category == 'hpchigh' ? this.btn_amount_3_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_3_hpclow : this.btn_amount_3_fixed }»</a></td>
                            </tr>
                            <tr>
                              <td align="center" bgcolor="${ this.bgcolor }" class="hvr-btn-${ this.hovercolor }" height="45" style="max-width: 310px; min-width: 310px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="310"><a class="hvr-btn-${ this.hovercolor }" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.amount_category == 'hpchigh' ? this.btn_amount_4_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_4_hpclow : this.btn_amount_4_fixed }&amounts=${ this.amount_category == 'hpchigh' ? this.btn_amounts_hpchigh : this.amount_category == 'hpclow' ? this.btn_amounts_hpclow : this.btn_amounts_fixed }" style="padding: 12px; background-color: ${ this.bgcolor }; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: ${ this.font_color }; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">CONTRIBUTE $${ this.amount_category == 'hpchigh' ? this.btn_amount_4_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_4_hpclow : this.btn_amount_4_fixed }»</a></td>
                            </tr>
                            <tr>
                              <td align="center" bgcolor="#6b6b6b" class="hvr-btn-gray" height="45" style="max-width: 310px; min-width: 310px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="310"><a class="hvr-btn-gray" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.amount_category == 'hpchigh' ? this.btn_amount_2_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_2_hpclow : this.btn_amount_2_fixed }&amounts=${ this.amount_category == 'hpchigh' ? this.btn_amounts_hpchigh : this.amount_category == 'hpclow' ? this.btn_amounts_hpclow : this.btn_amounts_fixed }" style="padding: 12px; background-color: #6b6b6b; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: #ffffff; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">Or a custom donation&nbsp;»</a></td>
                            </tr>
                          </tbody>
                        </table>
                      </center>
                      ${ this.crm == 'ak' ? '{% else %}' : '{{else}}' }
                      <center>
                        <table align="center" cellpadding="0" cellspacing="12" height="45" style="max-width: 100%;" width="320">
                          <tbody>
                            <tr>
                              <td align="center" bgcolor="${ this.bgcolor }" class="hvr-btn-${ this.hovercolor }" height="45" style="max-width: 310px; min-width: 310px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="310"><a class="hvr-btn-${ this.hovercolor }" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.btn_amount_1_nondonor }&amounts=${ this.btn_amounts_nondonor }" style="padding: 12px; background-color: ${ this.bgcolor }; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: ${ this.font_color }; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">CONTRIBUTE $${ this.btn_amount_1_nondonor }»</a></td>
                            </tr>
                            <tr>
                              <td align="center" bgcolor="${ this.bgcolor }" class="hvr-btn-${ this.hovercolor }" height="45" style="max-width: 310px; min-width: 310px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="310"><a class="hvr-btn-${ this.hovercolor }" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.btn_amount_2_nondonor }&amounts=${ this.btn_amounts_nondonor }" style="padding: 12px; background-color: ${ this.bgcolor }; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: ${ this.font_color }; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">CONTRIBUTE $${ this.btn_amount_2_nondonor }»</a></td>
                            </tr>
                            <tr>
                              <td align="center" bgcolor="#6b6b6b" class="hvr-btn-gray" height="45" style="max-width: 310px; min-width: 310px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="310"><a class="hvr-btn-gray" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.btn_amount_3_nondonor }&amounts=${ this.btn_amounts_nondonor }" style="padding: 12px; background-color: #6b6b6b; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: #ffffff; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">Or a custom donation&nbsp;»</a></td>
                            </tr>
                          </tbody>
                        </table>
                      </center>
                      ${ this.crm == 'ak' ? '{%endif%}' : '{{end}}' }
            `
        },
        mid_button_set_non_abx() {
            return `
                ${ this.crm == 'ak' ? '{% if hpc_raw %}' : '{{if HighestPreviousContributionAmount}}' }
                <center>
                    <table align="center" cellspacing="12" cellpadding="0" height="45" style="max-width: 100%;" width="320">
                    <tbody>
                        <tr>
                        <td align="center" bgcolor="${ this.bgcolor }" class="hvr-btn-${ this.hovercolor }" height="45" style="max-width: 150px; min-width: 150px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="150">
                            <a class="hvr-btn-${ this.hovercolor }" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.amount_category == 'hpchigh' ? this.btn_amount_1_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_1_hpclow : this.btn_amount_1_fixed }&amounts=${ this.amount_category == 'hpchigh' ? this.btn_amounts_hpchigh : this.amount_category == 'hpclow' ? this.btn_amounts_hpclow : this.btn_amounts_fixed }" style="padding: 12px; background-color: ${ this.bgcolor }; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: ${ this.font_color }; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">$${ this.amount_category == 'hpchigh' ? this.btn_amount_1_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_1_hpclow : this.btn_amount_1_fixed }&nbsp;»</a></td>
                        <td align="center" bgcolor="${ this.bgcolor }" class="hvr-btn-${ this.hovercolor }" height="45" style="max-width: 150px; min-width: 150px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="150">
                            <a class="hvr-btn-${ this.hovercolor }" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.amount_category == 'hpchigh' ? this.btn_amount_2_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_2_hpclow : this.btn_amount_2_fixed }&amounts=${ this.amount_category == 'hpchigh' ? this.btn_amounts_hpchigh : this.amount_category == 'hpclow' ? this.btn_amounts_hpclow : this.btn_amounts_fixed }" style="padding: 12px; background-color: ${ this.bgcolor }; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: ${ this.font_color }; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">$${ this.amount_category == 'hpchigh' ? this.btn_amount_2_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_2_hpclow : this.btn_amount_2_fixed }&nbsp;»</a></td>
                        </tr>
                        <tr>
                        <td align="center" bgcolor="${ this.bgcolor }" class="hvr-btn-${ this.hovercolor }" height="45" style="max-width: 150px; min-width: 150px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="150">
                            <a class="hvr-btn-${ this.hovercolor }" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.amount_category == 'hpchigh' ? this.btn_amount_3_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_3_hpclow : this.btn_amount_3_fixed }&amounts=${ this.amount_category == 'hpchigh' ? this.btn_amounts_hpchigh : this.amount_category == 'hpclow' ? this.btn_amounts_hpclow : this.btn_amounts_fixed }" style="padding: 12px; background-color: ${ this.bgcolor }; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: ${ this.font_color }; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">$${ this.amount_category == 'hpchigh' ? this.btn_amount_3_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_3_hpclow : this.btn_amount_3_fixed }&nbsp;»</a></td>
                        <td align="center" bgcolor="${ this.bgcolor }" class="hvr-btn-${ this.hovercolor }" height="45" style="max-width: 150px; min-width: 150px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="150">
                            <a class="hvr-btn-${ this.hovercolor }" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.amount_category == 'hpchigh' ? this.btn_amount_4_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_4_hpclow : this.btn_amount_4_fixed }&amounts=${ this.amount_category == 'hpchigh' ? this.btn_amounts_hpchigh : this.amount_category == 'hpclow' ? this.btn_amounts_hpclow : this.btn_amounts_fixed }" style="padding: 12px; background-color: ${ this.bgcolor }; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: ${ this.font_color }; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">$${ this.amount_category == 'hpchigh' ? this.btn_amount_4_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_4_hpclow : this.btn_amount_4_fixed }&nbsp;»</a></td>
                        </tr>
                        <tr>
                        <td align="center" bgcolor="${ this.bgcolor }" class="hvr-btn-${ this.hovercolor }" height="45" style="max-width: 150px; min-width: 150px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="150">
                            <a class="hvr-btn-${ this.hovercolor }" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.amount_category == 'hpchigh' ? this.btn_amount_5_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_5_hpclow : this.btn_amount_5_fixed }&amounts=${ this.amount_category == 'hpchigh' ? this.btn_amounts_hpchigh : this.amount_category == 'hpclow' ? this.btn_amounts_hpclow : this.btn_amounts_fixed }" style="padding: 12px; background-color: ${ this.bgcolor }; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: ${ this.font_color }; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">$${ this.amount_category == 'hpchigh' ? this.btn_amount_5_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_5_hpclow : this.btn_amount_5_fixed }&nbsp;»</a></td>
                        <td align="center" bgcolor="#6b6b6b" class="hvr-btn-gray" height="45" style="max-width: 150px; min-width: 150px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="150">
                            <a class="hvr-btn-gray" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.amount_category == 'hpchigh' ? this.btn_amount_2_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_2_hpclow : this.btn_amount_2_fixed }&amounts=${ this.amount_category == 'hpchigh' ? this.btn_amounts_hpchigh : this.amount_category == 'hpclow' ? this.btn_amounts_hpclow : this.btn_amounts_fixed }" style="padding: 12px; background-color: #6b6b6b; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: #ffffff; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">Custom&nbsp;»</a></td>
                        </tr>
                    </tbody>
                    </table>
                </center>
                ${ this.crm == 'ak' ? '{% else %}' : '{{else}}' }
                <center>
                    <table align="center" cellpadding="0" cellspacing="12" height="45" style="max-width: 100%;" width="320">
                    <tbody>
                        <tr>
                        <td align="center" bgcolor="${ this.bgcolor }" class="hvr-btn-${ this.hovercolor }" height="45" style="max-width: 310px; min-width: 310px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="310"><a class="hvr-btn-${ this.hovercolor }" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.btn_amount_1_nondonor }&amounts=${ this.btn_amounts_nondonor }" style="padding: 12px; background-color: ${ this.bgcolor }; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: ${ this.font_color }; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">CONTRIBUTE $${ this.btn_amount_1_nondonor }&nbsp;»</a></td>
                        </tr>
                        <tr>
                        <td align="center" bgcolor="${ this.bgcolor }" class="hvr-btn-${ this.hovercolor }" height="45" style="max-width: 310px; min-width: 310px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="310"><a class="hvr-btn-${ this.hovercolor }" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.btn_amount_2_nondonor }&amounts=${ this.btn_amounts_nondonor }" style="padding: 12px; background-color: ${ this.bgcolor }; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: ${ this.font_color }; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">CONTRIBUTE $${ this.btn_amount_2_nondonor }&nbsp;»</a></td>
                        </tr>
                        <tr>
                        <td align="center" bgcolor="#6b6b6b" class="hvr-btn-gray" height="45" style="max-width: 310px; min-width: 310px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="310"><a class="hvr-btn-gray" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.btn_amount_3_nondonor }&amounts=${ this.btn_amounts_nondonor }" style="padding: 12px; background-color: #6b6b6b; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: #ffffff; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">Or a custom donation&nbsp;»</a></td>
                        </tr>
                    </tbody>
                    </table>
                </center>
                ${ this.crm == 'ak' ? '{%endif%}' : '{{end}}' }
            `
        },
        full_button_set_abx() {
            return `
                <center>
                <p style="font-family: 'Proxima Nova', proxima-nova, Avenir, sans-serif; font-size: 13px; color: #393939; line-height: 110%; max-width: 450px; width: 100%;">
                    If you've saved your payment information with ActBlue Express, your donation will go through immediately:</p>
                </center>

                ${ this.crm == 'ak' ? '{% if hpc_raw %}' : '{{if HighestPreviousContributionAmount}}' }
                <center>
                <table align="center" cellpadding="0" cellspacing="12" height="45" style="max-width: 100%;" width="320">
                    <tbody>
                    <tr>
                        <td align="center" bgcolor="${ this.bgcolor }" class="hvr-btn-${ this.hovercolor }" height="45" style="max-width: 310px; min-width: 310px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="310"><a class="hvr-btn-${ this.hovercolor }" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.amount_category == 'hpchigh' ? this.btn_amount_1_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_1_hpclow : this.btn_amount_1_fixed }&amounts=${ this.amount_category == 'hpchigh' ? this.btn_amounts_hpchigh : this.amount_category == 'hpclow' ? this.btn_amounts_hpclow : this.btn_amounts_fixed }&${ this.abx }" style="padding: 12px; background-color: ${ this.bgcolor }; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: ${ this.font_color }; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">CONTRIBUTE $${ this.amount_category == 'hpchigh' ? this.btn_amount_1_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_1_hpclow : this.btn_amount_1_fixed }&nbsp;»</a></td>
                    </tr>
                    <tr>
                        <td align="center" bgcolor="${ this.bgcolor }" class="hvr-btn-${ this.hovercolor }" height="45" style="max-width: 310px; min-width: 310px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="310"><a class="hvr-btn-${ this.hovercolor }" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.amount_category == 'hpchigh' ? this.btn_amount_2_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_2_hpclow : this.btn_amount_2_fixed }&amounts=${ this.amount_category == 'hpchigh' ? this.btn_amounts_hpchigh : this.amount_category == 'hpclow' ? this.btn_amounts_hpclow : this.btn_amounts_fixed }&${ this.abx }" style="padding: 12px; background-color: ${ this.bgcolor }; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: ${ this.font_color }; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">CONTRIBUTE $${ this.amount_category == 'hpchigh' ? this.btn_amount_2_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_2_hpclow : this.btn_amount_2_fixed }&nbsp;»</a></td>
                    </tr>
                    <tr>
                        <td align="center" bgcolor="${ this.bgcolor }" class="hvr-btn-${ this.hovercolor }" height="45" style="max-width: 310px; min-width: 310px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="310"><a class="hvr-btn-${ this.hovercolor }" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.amount_category == 'hpchigh' ? this.btn_amount_3_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_3_hpclow : this.btn_amount_3_fixed }&amounts=${ this.amount_category == 'hpchigh' ? this.btn_amounts_hpchigh : this.amount_category == 'hpclow' ? this.btn_amounts_hpclow : this.btn_amounts_fixed }&${ this.abx }" style="padding: 12px; background-color: ${ this.bgcolor }; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: ${ this.font_color }; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">CONTRIBUTE $${ this.amount_category == 'hpchigh' ? this.btn_amount_3_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_3_hpclow : this.btn_amount_3_fixed }&nbsp;»</a></td>
                    </tr>
                    <tr>
                        <td align="center" bgcolor="${ this.bgcolor }" class="hvr-btn-${ this.hovercolor }" height="45" style="max-width: 310px; min-width: 310px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="310"><a class="hvr-btn-${ this.hovercolor }" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.amount_category == 'hpchigh' ? this.btn_amount_4_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_4_hpclow : this.btn_amount_4_fixed }&amounts=${ this.amount_category == 'hpchigh' ? this.btn_amounts_hpchigh : this.amount_category == 'hpclow' ? this.btn_amounts_hpclow : this.btn_amounts_fixed }&${ this.abx }" style="padding: 12px; background-color: ${ this.bgcolor }; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: ${ this.font_color }; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">CONTRIBUTE $${ this.amount_category == 'hpchigh' ? this.btn_amount_4_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_4_hpclow : this.btn_amount_4_fixed }&nbsp;»</a></td>
                    </tr>
                    <tr>
                        <td align="center" bgcolor="#6b6b6b" class="hvr-btn-gray" height="45" style="max-width: 310px; min-width: 310px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="310"><a class="hvr-btn-gray" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.amount_category == 'hpchigh' ? this.btn_amount_2_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_2_hpclow : this.btn_amount_2_fixed }&amounts=${ this.amount_category == 'hpchigh' ? this.btn_amounts_hpchigh : this.amount_category == 'hpclow' ? this.btn_amounts_hpclow : this.btn_amounts_fixed }" style="padding: 12px; background-color: #6b6b6b; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: #ffffff; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">Or a custom donation&nbsp;»</a></td>
                    </tr>
                    </tbody>
                </table>
                </center>
                ${ this.crm == 'ak' ? '{% else %}' : '{{else}}' }
                <center>
                <table align="center" cellpadding="0" cellspacing="12" height="45" style="max-width: 100%;" width="320">
                    <tbody>
                    <tr>
                        <td align="center" bgcolor="${ this.bgcolor }" class="hvr-btn-${ this.hovercolor }" height="45" style="max-width: 310px; min-width: 310px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="310"><a class="hvr-btn-${ this.hovercolor }" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.btn_amount_1_nondonor }&amounts=${ this.btn_amounts_nondonor }&${ this.abx }" style="padding: 12px; background-color: ${ this.bgcolor }; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: ${ this.font_color }; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">CONTRIBUTE $${ this.btn_amount_1_nondonor }&nbsp;»</a></td>
                    </tr>
                    <tr>
                        <td align="center" bgcolor="${ this.bgcolor }" class="hvr-btn-${ this.hovercolor }" height="45" style="max-width: 310px; min-width: 310px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="310"><a class="hvr-btn-${ this.hovercolor }" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.btn_amount_2_nondonor }&amounts=${ this.btn_amounts_nondonor }&${ this.abx }" style="padding: 12px; background-color: ${ this.bgcolor }; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: ${ this.font_color }; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">CONTRIBUTE $${ this.btn_amount_2_nondonor }&nbsp;»</a></td>
                    </tr>
                    <tr>
                        <td align="center" bgcolor="#6b6b6b" class="hvr-btn-gray" height="45" style="max-width: 310px; min-width: 310px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="310"><a class="hvr-btn-gray" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.btn_amount_3_nondonor }&amounts=${ this.btn_amounts_nondonor }" style="padding: 12px; background-color: #6b6b6b; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: #ffffff; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">Or a custom donation&nbsp;»</a></td>
                    </tr>
                    </tbody>
                </table>
                </center>
                ${ this.crm == 'ak' ? '{%endif%}' : '{{end}}' }
            `
        },
        mid_button_set_abx() {
            return `
                <center>
                <p style="font-family: 'Proxima Nova', proxima-nova, Avenir, sans-serif; font-size: 13px; color: #393939; line-height: 110%; max-width: 450px; width: 100%;">
                    If you've saved your payment information with ActBlue Express, your donation will go through immediately:</p>
                </center>

                ${ this.crm == 'ak' ? '{% if hpc_raw %}' : '{{if HighestPreviousContributionAmount}}' }
                <center>
                <table align="center" cellspacing="12" cellpadding="0" height="45" style="max-width: 100%;" width="320">
                    <tbody>
                    <tr>
                        <td align="center" bgcolor="${ this.bgcolor }" class="hvr-btn-${ this.hovercolor }" height="45" style="max-width: 150px; min-width: 150px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="150">
                        <a class="hvr-btn-${ this.hovercolor }" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.amount_category == 'hpchigh' ? this.btn_amount_1_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_1_hpclow : this.btn_amount_1_fixed }&amounts=${ this.amount_category == 'hpchigh' ? this.btn_amounts_hpchigh : this.amount_category == 'hpclow' ? this.btn_amounts_hpclow : this.btn_amounts_fixed }&${ this.abx }" style="padding: 12px; background-color: ${ this.bgcolor }; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: ${ this.font_color }; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">$${ this.amount_category == 'hpchigh' ? this.btn_amount_1_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_1_hpclow : this.btn_amount_1_fixed }&nbsp;»</a></td>
                        <td align="center" bgcolor="${ this.bgcolor }" class="hvr-btn-${ this.hovercolor }" height="45" style="max-width: 150px; min-width: 150px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="150">
                        <a class="hvr-btn-${ this.hovercolor }" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.amount_category == 'hpchigh' ? this.btn_amount_2_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_2_hpclow : this.btn_amount_2_fixed }&amounts=${ this.amount_category == 'hpchigh' ? this.btn_amounts_hpchigh : this.amount_category == 'hpclow' ? this.btn_amounts_hpclow : this.btn_amounts_fixed }&${ this.abx }" style="padding: 12px; background-color: ${ this.bgcolor }; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: ${ this.font_color }; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">$${ this.amount_category == 'hpchigh' ? this.btn_amount_2_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_2_hpclow : this.btn_amount_2_fixed }&nbsp;»</a></td>
                    </tr>
                    <tr>
                        <td align="center" bgcolor="${ this.bgcolor }" class="hvr-btn-${ this.hovercolor }" height="45" style="max-width: 150px; min-width: 150px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="150">
                        <a class="hvr-btn-${ this.hovercolor }" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.amount_category == 'hpchigh' ? this.btn_amount_3_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_3_hpclow : this.btn_amount_3_fixed }&amounts=${ this.amount_category == 'hpchigh' ? this.btn_amounts_hpchigh : this.amount_category == 'hpclow' ? this.btn_amounts_hpclow : this.btn_amounts_fixed }&${ this.abx }" style="padding: 12px; background-color: ${ this.bgcolor }; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: ${ this.font_color }; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">$${ this.amount_category == 'hpchigh' ? this.btn_amount_3_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_3_hpclow : this.btn_amount_3_fixed }&nbsp;»</a></td>
                        <td align="center" bgcolor="${ this.bgcolor }" class="hvr-btn-${ this.hovercolor }" height="45" style="max-width: 150px; min-width: 150px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="150">
                        <a class="hvr-btn-${ this.hovercolor }" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.amount_category == 'hpchigh' ? this.btn_amount_4_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_4_hpclow : this.btn_amount_4_fixed }&amounts=${ this.amount_category == 'hpchigh' ? this.btn_amounts_hpchigh : this.amount_category == 'hpclow' ? this.btn_amounts_hpclow : this.btn_amounts_fixed }&${ this.abx }" style="padding: 12px; background-color: ${ this.bgcolor }; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: ${ this.font_color }; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">$${ this.amount_category == 'hpchigh' ? this.btn_amount_4_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_4_hpclow : this.btn_amount_4_fixed }&nbsp;»</a></td>
                    </tr>
                    <tr>
                        <td align="center" bgcolor="${ this.bgcolor }" class="hvr-btn-${ this.hovercolor }" height="45" style="max-width: 150px; min-width: 150px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="150">
                        <a class="hvr-btn-${ this.hovercolor }" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.amount_category == 'hpchigh' ? this.btn_amount_5_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_5_hpclow : this.btn_amount_5_fixed }&amounts=${ this.amount_category == 'hpchigh' ? this.btn_amounts_hpchigh : this.amount_category == 'hpclow' ? this.btn_amounts_hpclow : this.btn_amounts_fixed }&${ this.abx }" style="padding: 12px; background-color: ${ this.bgcolor }; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: ${ this.font_color }; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">$${ this.amount_category == 'hpchigh' ? this.btn_amount_5_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_5_hpclow : this.btn_amount_5_fixed }&nbsp;»</a></td>
                        <td align="center" bgcolor="#6b6b6b" class="hvr-btn-gray" height="45" style="max-width: 150px; min-width: 150px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="150">
                        <a class="hvr-btn-gray" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.amount_category == 'hpchigh' ? this.btn_amount_2_hpchigh : this.amount_category == 'hpclow' ? this.btn_amount_2_hpclow : this.btn_amount_2_fixed }&amounts=${ this.amount_category == 'hpchigh' ? this.btn_amounts_hpchigh : this.amount_category == 'hpclow' ? this.btn_amounts_hpclow : this.btn_amounts_fixed }" style="padding: 12px; background-color: #6b6b6b; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: #ffffff; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">Custom&nbsp;»</a></td>
                    </tr>
                    </tbody>
                </table>
                </center>
                ${ this.crm == 'ak' ? '{% else %}' : '{{else}}' }
                <center>
                <table align="center" cellpadding="0" cellspacing="12" height="45" style="max-width: 100%;" width="320">
                    <tbody>
                    <tr>
                        <td align="center" bgcolor="${ this.bgcolor }" class="hvr-btn-${ this.hovercolor }" height="45" style="max-width: 310px; min-width: 310px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="310"><a class="hvr-btn-${ this.hovercolor }" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.btn_amount_1_nondonor }&amounts=${ this.btn_amounts_nondonor }&${ this.abx }" style="padding: 12px; background-color: ${ this.bgcolor }; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: ${ this.font_color }; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">CONTRIBUTE $${ this.btn_amount_1_nondonor }&nbsp;»</a></td>
                    </tr>
                    <tr>
                        <td align="center" bgcolor="${ this.bgcolor }" class="hvr-btn-${ this.hovercolor }" height="45" style="max-width: 310px; min-width: 310px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="310"><a class="hvr-btn-${ this.hovercolor }" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.btn_amount_2_nondonor }&amounts=${ this.btn_amounts_nondonor }&${ this.abx }" style="padding: 12px; background-color: ${ this.bgcolor }; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: ${ this.font_color }; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">CONTRIBUTE $${ this.btn_amount_2_nondonor }&nbsp;»</a></td>
                    </tr>
                    <tr>
                        <td align="center" bgcolor="#6b6b6b" class="hvr-btn-gray" height="45" style="max-width: 310px; min-width: 310px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="310"><a class="hvr-btn-gray" href="https://secure.actblue.com/donate/${ this.abpage }?amount=${ this.btn_amount_3_nondonor }&amounts=${ this.btn_amounts_nondonor }" style="padding: 12px; background-color: #6b6b6b; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: #ffffff; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">Or a custom donation&nbsp;»</a></td>
                    </tr>
                    </tbody>
                </table>
                </center>
                ${ this.crm == 'ak' ? '{%endif%}' : '{{end}}' }
            `
        },
        single_button() {
            return `
                <center>
                <table align="center" cellpadding="0" cellspacing="12" height="45" style="max-width: ${ this.single_button_width };" width="100%">
                    <tbody>
                    <tr>
                        <td align="center" bgcolor="${ this.bgcolor }" class="hvr-btn-${ this.hovercolor }" height="45" style="max-width: ${ this.single_button_width }; min-width: 280px; border: ${ this.border }; border-bottom: ${ this.border_bottom }; box-shadow: ${ this.shadow }; border-radius: ${ this.border_radius };" width="100%"><a class="hvr-btn-${ this.hovercolor }" href="#" style="padding: 12px; background-color: ${ this.bgcolor }; font-family: 'Proxima Nova Condensed', 'Proxima Nova', ${ this.font_family }; font-size: ${ this.font_size }; color: ${ this.font_color }; font-weight: 800; letter-spacing: ${ this.letter_spacing }; text-align: center; text-decoration: none; display: block; line-height: ${ this.line_height };">${ this.single_button_text }&nbsp;»</a></td>
                    </tr>
                    </tbody>
                </table>
                </center>
            `
        },
        donor_nondonor_twig() {
            if (this.crm == 'ak') {
                return `
                    {% set refcode = '' %}
                    {% set abpage = '${ this.abpage }' %}
                    {% set amount1_nondonor = ${ this.btn_amount_1_nondonor } %}
                    {% set amount2_nondonor = ${ this.btn_amount_2_nondonor } %}
                    {% set amount3_nondonor = ${ this.btn_amount_3_nondonor } %}
                    {% set amount1_fixed = ${ this.btn_amount_1_fixed } %}
                    {% set amount2_fixed = ${ this.btn_amount_2_fixed } %}
                    {% set amount3_fixed = ${ this.btn_amount_3_fixed } %}
                    {% set amount4_fixed = ${ this.btn_amount_4_fixed } %}
                    {% set max_amount1_fixed = 50 %}
                    {% set min_amount1_fixed = 10 %}
                    {% set factor = 0.8 %}
                    {% set amount1_fixed = min(max_amount1_fixed, max(min_amount1_fixed, factor * hpc_raw)) | round %}
                    {% set max_amount2_fixed = 100 %}
                    {% set min_amount2_fixed = 18 %}
                    {% set factor = 1.25 %}
                    {% set amount2_fixed = min(max_amount2_fixed, max(min_amount2_fixed, factor * hpc_raw)) | round %}
                    {% set max_amount3_fixed = 150 %}
                    {% set min_amount3_fixed = 35 %}
                    {% set factor = 2.5 %}
                    {% set amount3_fixed = min(max_amount3_fixed, max(min_amount3_fixed, factor * hpc_raw)) | round %}
                    {% set max_amount4_fixed = 250 %}
                    {% set min_amount4_fixed = 70 %}
                    {% set factor = 5 %}
                    {% set amount4_fixed = min(max_amount4_fixed, max(min_amount4_fixed, factor * hpc_raw)) | round %}
                    {% set max_amount5_fixed = 1500 %}
                    {% set min_amount5_fixed = 133 %}
                    {% set factor = 9.5 %}
                    {% set amount5_fixed = min(max_amount5_fixed, max(min_amount5_fixed, factor * hpc_raw)) | round %}
                    {% set max_amount1_hpchigh = 50 %}
                    {% set min_amount1_hpchigh = 10 %}
                    {% set factor = 1.25 %}
                    {% set amount1_hpchigh = min(max_amount1_hpchigh, max(min_amount1_hpchigh, factor * hpc_raw)) | round %}
                    {% set max_amount2_hpchigh = 100 %}
                    {% set min_amount2_hpchigh = 25 %}
                    {% set factor = 2 %}
                    {% set amount2_hpchigh = min(max_amount2_hpchigh, max(min_amount2_hpchigh, factor * hpc_raw)) | round %}
                    {% set max_amount3_hpchigh = 250 %}
                    {% set min_amount3_hpchigh = 50 %}
                    {% set factor = 4.25 %}
                    {% set amount3_hpchigh = min(max_amount3_hpchigh, max(min_amount3_hpchigh, factor * hpc_raw)) | round %}
                    {% set max_amount4_hpchigh = 500 %}
                    {% set min_amount4_hpchigh = 100 %}
                    {% set factor = 7.5 %}
                    {% set amount4_hpchigh = min(max_amount4_hpchigh, max(min_amount4_hpchigh, factor * hpc_raw)) | round %}
                    {% set max_amount5_hpchigh = 1500 %}
                    {% set min_amount5_hpchigh = 250 %}
                    {% set factor = 10.5 %}
                    {% set amount5_hpchigh = min(max_amount5_hpchigh, max(min_amount5_hpchigh, factor * hpc_raw)) | round %}
                `;
            } else {
                return `
                    {{if HighestPreviousContributionAmount}}Donor Language Goes Here{{else}}NonDonor Language Goes Here{{end}}
                `;
            }
        },
        client_doc_basket() {
            return this.client_doc;
        }
    },
    components: {
        RefcodeTool,
    },
    mounted() {
        setTimeout(() => { 
            this.loading = false;
            this.current_client = this.client_doc.name;
            for (let item of Object.keys(this.client_doc)) {
                if (this[item]) {
                    this[item] = this.client_doc[item];
                }
            }
        }, 500)
    }
}
</script>

<style>
    a.hvr-btn-blue:hover {
        background-color: #1fccff !important;
        color: #ffffff !important;
        transition: all ease 0.6s;
    }

    td.hvr-btn-blue:hover {
        background-color: #1fccff !important;
        color: #ffffff !important;
        transition: all ease 0.6s;
    }

    a.hvr-btn-darkblue:hover {
        background-color: #24425d !important;
        color: #ffffff !important;
        transition: all ease 0.6s;
    }

    td.hvr-btn-darkblue:hover {
        background-color: #24425d !important;
        color: #ffffff !important;
        transition: all ease 0.6s;
    }

    a.hvr-btn-red:hover {
        background-color: #d44a47 !important;
        color: #ffffff !important;
        transition: all ease 0.6s;
    }

    td.hvr-btn-red:hover {
        background-color: #d44a47 !important;
        color: #ffffff !important;
        transition: all ease 0.6s;
    }

    a.hvr-btn-gray:hover {
        background-color: #858585 !important;
        color: #ffffff !important;
        transition: all ease 0.6s;
    }

    td.hvr-btn-gray:hover {
        background-color: #858585 !important;
        color: #ffffff !important;
        transition: all ease 0.6s;
    }

    a.hvr-link:hover {
        color: #0066cc !important;
    }

    a.hvr-link-red:hover {
        color: #932522 !important;
    }

</style>